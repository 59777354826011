body {
  margin: 0;
}

html { scroll-behavior: smooth; } 

h1, h2, h3 {
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0 !important;
}

p, a {
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0 !important;
}

a:hover{
  text-decoration: none !important;
}

.nav-link {
  cursor: pointer;
}
