
// n is number of stars required
@function multiple-box-shadow ($n) {
    $value: '#{random(2000)}px #{random(2000)}px #FFF';
    @for $i from 2 through $n {
        $value: '#{$value} , #{random(2000)}px #{random(2000)}px #FFF';
    }
    @return unquote($value)
}

$shadows-small:  multiple-box-shadow(700);
$shadows-medium: multiple-box-shadow(200);
$shadows-big:    multiple-box-shadow(100);

.App-header {  
    background: radial-gradient(ellipse at bottom, #1B2735 0%, #090A0F 100%);
    overflow: hidden;
}

#canvasjs-react-chart-container-1 {
  z-index: 1;
}

.zindex {
  position: relative;
  z-index: 1
}
    
#stars {
    width: 1px;
    height: 1px;
    z-index: -2;
    background: transparent;
    box-shadow: $shadows-small;
    animation: animStar 50s linear infinite;
        
    &:after {
        content: " ";
        position: absolute;
        top: -3000px;
        width: 1px;
        height: 1px;
        background: transparent;
        box-shadow: $shadows-small;
    }
}
    
#stars2 {
    width: 2px;
    height: 2px;
    z-index: -2;
    background: transparent;
    box-shadow: $shadows-medium;
    animation: animStar 100s linear infinite;
        
    &:after{
        content: " ";
        position: absolute;
        top: -3000px;
        width: 2px;
        height: 2px;
        background: transparent;
        box-shadow: $shadows-medium;
    }
}
    
#stars3 {
    width: 3px;
    height: 3px;
    background: transparent;
    z-index: -2;
    box-shadow: $shadows-big;
    animation: animStar 150s linear infinite;

    &:after {
        content: " ";
        position: absolute;
        top: -3000px;
        width: 3px;
        height: 3px;
        background: transparent;
        box-shadow: $shadows-big;
    }
}

.test {
    padding-top: 20px;
    position: relative;
    z-index: 1;
}

@keyframes animStar {
    from {	
        transform: translateY(0px);
    }
    to {		
        transform: translateY(-2000px);
    }
}

.abutton {
  min-width: 120px;
  position: relative;
  display: inline-block;
  z-index: 1;

  text-align: center;
  text-transform: uppercase; 

  padding: 5px 30px;
  margin: 20px;

  transition: 0.5s;

  
  border: 5px solid white;
  color: white;
  background-color: transparent;
}
.readmore {
    min-width: 120px;
    z-index: 1;

    line-height: 0 !important;

    text-align: center;
    text-transform: uppercase; 

    padding: 5px 30px;
    margin: 20px;

    transition: 0.5s;

    
    border: 5px solid white;
    color: white;
    background-color: transparent;
}

.readmore:hover, .abutton:hover {
  color: white;
	background-color: darken(#F02D3A, 5%);
	border: 5px solid darken(#F02D3A, 5%);
	cursor: pointer;
}

.navigation {   
    position: fixed;  
    width: 100%;
    top: 0;
    color: white;
    z-index: 2;
  }

  .navigation .items {
    display: flex;
    justify-content: center;
  }

  .navbar-collapse {
    display: flex;
    justify-content: center;
  }
  
  .navigation a{
    transition: 0.2s;
    color: white !important;
  }
  .navigation a:hover{
    color: darken(#F02D3A, 5%) !important;
  }
  .articlesContent a {
    color: #F02D3A;
    padding: 0 10px;
  }
  .articlesContent a:hover {
    color: darken(#F02D3A, 5%) !important;
  }

  .faicon{
    height: 50px;
    width: 50px;
    margin: 0 15px;
    transition: 0.2s;
    color: white;
  }

  
  .faicon:hover{
    color: darken(#F02D3A, 5%)
  }