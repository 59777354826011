.App {
  overflow: hidden;
}

.highlight{
  color: #F02D3A;
}

.App-logo {
  height: 40vmin;
  position: relative;
  pointer-events: none;
  z-index: 1;
}

#home {
  padding: 100px 0 500px;
  text-align: center;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  /*background-color: #282c34;*/
  color: white;
  text-align: center;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.headingcontent {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

.headingcontent h1{
  margin: 10px 0 !important;
}


.navigation a {
  padding: 10px 15px;
}

#about h1, #projects h1, #testimonials h1, #contact h1, #client h1 {
  padding: 50px 0;
}

#about img {
  border: solid #282c34 4px;
}

#about p {
  padding: 25px;
}

.aboutcontent2 {
  display: block;
}

#about, #projects, #testimonials, #contact, #client {
  padding: 50px 0;
}

#contact {
  color: white;
  /*background-color: #223843;*/
}

.footer {
  color: white;
  background-color: #323843;
}

.articles {
  margin-bottom: 25px;
  display: block;
}

.articles img {
  border: solid #282c34 4px;
}

.articlesContent {
  padding: 25px 0;
  display: inline-block;
}

.articlesContent p {
  padding: 30px;
  text-align: justify
}

.articles h3{
  color: #F02D3A;
}

.articles img {
  width:100%; 
  height: 50%;
}

.testimonialcontent {
  margin: 25px 0;
}

#contact a:hover{
  text-decoration: none;
}

.faiconcontent{
  padding: 40px 0;
}

.footer {
  padding: 30px 0;
}

.canvasjs-chart-credit {
  width: 100%;
  color: white !important;
  background-color: white;
  left: 4px !important; 
  border: solid #282c34 4px;
  border-top: none;
}

.canvasjs-chart-canvas {
  border: solid #282c34 4px;
  margin: 4px;
}

.blur {
  position: absolute;
  background: -moz-linear-gradient(top, rgba(9, 10, 15,1) 0%, rgba(9, 10, 15,1) 40%, rgba(9, 10, 15,0) 100%);
  width: 100%;
  height: 125%;
  top: 0;
  z-index: -1;
}

.navbar {
  padding: 10px 0 15px 0;
}